.templateCard {
  margin-top: 20px;
  padding-top: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 600px;
  min-width: 400px !important;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid transparent;
  position: relative;
}

.template-card-selected {
  border: 3px solid green;
}

.templateCard .title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.templateCard .image-slideshow {
  width: 100%;
  text-align: center;
}

.templateCard .image-slideshow button {
  margin: 5px;
}

.templateCard .each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 337.5px;
}

.templateCard .select-template-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.templateCard .selected-text {
  height: 52px;
  color: green;
  font-weight: 700;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.templateCard .open-external-icon {
  position: absolute;
  top: 17px;
  right: 10px;
  cursor: pointer;
}
