.thumbnailContainer {
  display: flex;
  font-size: 0;
  flex-wrap: wrap;
}
.thumbnail {
  position: relative;
  margin-right: 5px;
  margin-top: 5px;
}
.deleteIcon {
  color: darkgray;
  position: absolute;
  top: 0;
  font-size: 50px;
  height: 100%;
  width: 100px;
  display: none;
  justify-content: center;
  border-radius: 5px;
}

.thumbnail:hover .deleteIcon {
  display: flex;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}

.endEditButton {
  display: block;
  margin-top: 10px;
}
