.template {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}
.template-header {
  font-size: 28px;
  margin-bottom: 20px;
}
content {
  font-size: 28px;
  margin-bottom: 20px;
}
.template-page {
  margin-top: 20px;
}
.wrap {
  width: 960px;
  height: 580px;
  padding: 0;
  overflow: hidden;
}

.wrap-mobile {
  width: 960px;
  height: 800px;
  padding: 0;
  overflow: hidden;
}

.fullscreen-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 10000;
  background-color: white;
}

.fullscreen-wrap-mobile {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 10000;
  background-color: rgba(212, 212, 212)!important;
  display: flex;
}

.fullScreenBtn {
  background-color: transparent;
  color: rgb(0, 200, 173);
  border-color: rgb(0, 200, 173);
  margin-left: auto;
  border-width: 2px;
  font-weight: 600;
  margin-bottom: 5px;
}

.mobileViewBtn {
  background-color: transparent;
  color: rgb(0, 200, 173);
  border-color: rgb(0, 200, 173);
  margin-left: auto;
  border-width: 2px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 10px;
}

.fullScreenBtn:hover {
  background-color: rgb(0, 200, 173);
  color: white !important;
  border-color: rgb(0, 200, 173) !important;
}

.exitFullScreenBtn {
  z-index: 20000;
  position: relative;
  left: 5px;
  top: 5px;
  background-color: rgb(24, 17, 85);
}

.exitFullScreenBtn:hover {
  background-color: rgb(0, 198, 171) !important;
}

.closeButtonIcon svg {
  height: 17px;
  width: 17px;
  margin-top: 3px;
}

.frame {
  width: 1920px;
  height: 1080px;
  border: 0;
  background-color: white;

  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 10000;
}

.mobileView {
  width: 680px;
  height: 1280px;
  border: 0;
  background-color: white;

  -ms-transform: scale(0.75);
  -moz-transform: scale(0.75);
  -o-transform: scale(0.75);
  -webkit-transform: scale(0.75);
  transform: scale(0.75);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.fullscreen.mobileView {
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
}