.dashboard {
  display: flex;
  font-family: "Inter";
}
.dashboard-nav {
  flex: 1;
  border-radius: 8px;
  border-style: none;
  margin: 0px 10px 20px 20px;
  color: #f1f5f9;
  padding: 10px;
}
.dashboard-content {
  flex: 5;
  border-radius: 8px;
  border-style: none;
  background-color: rgba(24, 17, 85, 0.3);
  margin: 0px 20px 20px 10px;
  padding: 20px;
  height: calc(100vh - 140px);
  max-height: calc(100vh - 120px);
  color: #f1f5f9;
  overflow-y: scroll;
}
.dashboard-menu {
  font-weight: 600;
  cursor: pointer;
  height: 50px;
}
.dashboard-menu-item {
  font-size: 24px;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
}
.dashboard-menu-item-sub {
  font-size: 20px;
}

.dashboard .website-settings-button {
  width: 100%;
  margin-bottom: 0.5rem;
}

.dashboard .page-section-button {
  width: 100%;
  color: white;
  background-color: rgba(255, 255, 255, 0.3) !important;
  margin-bottom: 20px;
}

.dashboard .page-section-button:hover {
  color: white !important;
  background-color: rgba(24, 17, 85, 0.3) !important;
}

.dashboard .dashboard-nav-title {
  font-size: 20px;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 50px;
  text-align: center;
  border-bottom: solid white 2px;
}
