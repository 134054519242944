.valueContainer {
  font-size: 1.125rem;
  display: flex;
  vertical-align: middle;
  line-height: 3rem;
}

.valueContainer .value {
  align-self: center;
  font-size: 15px;
  font-family: "Quicksand";
  line-height: 1.5rem;
  min-height: 35px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}