.largeButton {
  color: #fff;
  background-color: rgb(9, 128, 138);
  width: 100%;
  height: 50px;
  font-size: 15px;
  text-align: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.largeButton:disabled {
  color: #c9c9c9;
  background-color: rgb(121, 121, 121);
  cursor: not-allowed;
  align-items: center;
  justify-content: center;
  display: flex;
}
