.header {
  overflow-y: scroll;
  height: 60px
}

.header .logout-button:hover {
  color: white !important;
  background-color: rgba(24, 17, 85, 0.3) !important;
}

.header .logout-button {
  margin-left: 20px;
  color: white;
  background-color: rgba(255, 255, 255, 0.3) !important;

  position: absolute;
  top: 10px;
  right: 10px;
}

.loading-animation-container {
  height: 100vh;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}