.errorDiv {
  padding: 8px;
  border-radius: 5px;
  max-width: 60%;
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.2);
}

.copyButtonDiv {
  display: flex;
  justify-content: flex-end;
}

.copyButton {
  background-color: transparent;
  color: red;
  border-color: red;
  margin-left: auto;
  border-width: 2px;
  font-weight: 600;
  border-radius: 5px;
  padding: 4px;
}

.copyButton:hover {
  background-color: red;
  color: white!important;
  border-color: red!important;
}