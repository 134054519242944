body {
  margin: 0px;

  height: 100vh;
  background: linear-gradient(160deg, rgb(0, 198, 171), rgb(24, 17, 85));
}

.text-header-large {
  font-size: 46px;
}

.text-header-sm {
  font-size: 15px;
}

@font-face {
  font-family: "Dosis";
  src: url("../../../fonts/Dosis-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("../../../fonts/Inter-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  src: url("../../../fonts/Quicksand-VariableFont_wght.ttf") format("truetype");
}

::-webkit-scrollbar {
  width: 3px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: grey; /* color of the thumb */
}

/* Internet Explorer/Edge */
::-ms-scrollbar {
  width: 10px;
}

::-ms-scrollbar-track {
  background-color: transparent;
}

::-ms-scrollbar-thumb {
  background-color: #888;
}

* {
  /* for firefox */
  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}
