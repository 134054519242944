.loading-spinner {
    border: solid rgba(0, 198, 171, 0.1);
    border-top-color: rgb(0, 198, 171);
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }