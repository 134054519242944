.landing-root {
  height: 100vh;
  width: 100%;
}

.login-button-container {
  position: fixed;
  right: 0;
  margin: 10px;
  margin-right: 20px;
}

.main-content {
  height: 100%;
  /* width: 100%; */
  padding-top: 150px;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  max-width: 1600px;
}

.text-content {
  color: white;
  font-family: "Dosis";
  max-width: 60%;
}

.text-content-header {
  font-size: 60px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.text-content-subtext {
  font-size: 25px;
  letter-spacing: 1px;
}

.auth-layout-buttons {
  margin-top: 20px;
}

.landing-root .auth-button {
  color: white;
  background-color: rgba(255, 255, 255, 0.3) !important;
  margin-bottom: 20px;
}

.landing-root .auth-button:hover {
  color: white !important;
  background-color: rgba(24, 17, 85, 0.3) !important;
}
