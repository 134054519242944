
.cancelButton {
    background-color: transparent;
    color: red;
    border-color: red;
    margin-left: auto;
    border-width: 2px;
    font-weight: 600;
  }
  
  .cancelButton:hover {
    background-color: red;
    color: white!important;
    border-color: red!important;
  }