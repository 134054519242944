.templateSegment {
  margin-bottom: 50px;
}
.templateSegmentContent {
  display: flex;
}
.templateSegmentComponent {
  flex: 1;
}
.templateSegmentCurrentcontent {
  margin-left: 20px;
  flex: 1;
}
.templateSegmentTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  height: 32px;
  display: flex;
  align-items: center;
}
.templateSegmentTitleInList {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}
.templateSegmentInList {
  margin-bottom: 20px;
}

.editButton {
  margin-left: 10px !important;
}
