.saveButton {
  background-color: rgb(227, 255, 217);
  color: green;
  border-color: green;
  margin-left: auto;
  border-width: 2px;
  font-weight: 600;
}

.saveButton:hover:enabled {
  background-color: green;
  color: white;
  border-color: green;
}
