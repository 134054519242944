.editButton {
  background-color: transparent;
  color: rgb(0, 200, 173);
  border-color: rgb(0, 200, 173);
  margin-left: auto;
  border-width: 2px;
  font-weight: 600;
}

.editButton:hover {
  background-color: rgb(0, 200, 173);
  color: white !important;
  border-color: rgb(0, 200, 173) !important;
}
