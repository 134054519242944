.container {
  font-size: 1.125rem;
  display: flex;
  vertical-align: middle;
}

.display {
  line-height: 3rem;
}

.editButton {
  opacity: 0.25;
  align-self: center;
  margin-left: 0.25rem;
}

.container:hover .editButton {
  opacity: 1;
}

.saveButton {
  margin-left: 0.25rem;
}

.cancelButton {
  margin-left: 0.25rem;
}

.phoneNumber {
  font-size: 15px;
  font-family: "Quicksand";
  min-height: 35px;
}

.input {
  margin-bottom: 10px;
} 