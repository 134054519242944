.form {
  background-color: #ffffff;
  width: 500px;
  padding: 50px;
  overflow: hidden;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  z-index: 500;
  border-radius: 8px;

  font-family: "Dosis";
}

.form .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
}

.form .header .title {
  font-size: 40px;
  font-weight: bold;
  color: #000000;
}

.form .header .switch-mode {
  cursor: pointer;
  font-size: 16px;
  color: rgb(0, 198, 171);
}

.form .header .switch-mode-text {
  margin-right: 5px;
  color: #c4c4c4;
}

.form .input-group {
  margin-bottom: 50px;
}

.form .input-group .label {
  display: block;
  font-size: 20px;
  color: #4f4f4f;
  margin-bottom: 13px;
}

.form .input-group .error-box {
  cursor: pointer;
  font-size: 16px;
  color: red;
  margin-top: 5px;
  height: 25px;
}

.form .input {
  border: 1px solid #cccccc;
  color: #000000;
  font-size: 14px;
  display: block;
  width: 460px;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;

  background-color: #ffffff;
  border-radius: 8px;
}

.form .input.error {
  border: 1px solid red;
}