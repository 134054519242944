.container {
  font-size: 1.125rem;
  display: flex;
  vertical-align: middle;
  line-height: 3rem;

  flex-direction: column;
  width: 40rem;
}

.container .editButton {
  opacity: 0.25;
  align-self: center;
  margin-left: 0.25rem;
}

.container:hover .editButton {
  opacity: 1;
}

.cancelButton {
  margin-left: 0.25rem;
}

.addButton {
  display: block;
  background-color: transparent;
  border-color: rgb(0, 200, 173);
  border-width: 2px;
  color: rgb(0, 200, 173);
}

.addButton:hover {
  background-color: rgb(0, 200, 173) !important;
  color: white;
}

.addButtonIcon svg {
  height: 16px;
  width: 16px;
}
.cardContainer {
  display: flex;
  flex-direction: column;
}

.card {
  margin-top: 0.5rem;
}

.dayContent {
  display: flex;
  flex: 1;
}

.dayContentDisplay {
  border-bottom: solid;
  border-bottom-width: 1px;
  width: 18rem;
}

.removeCardButton {
  margin-left: 0.25rem;
  border-color: red;
  border-width: 2px !important;
  background-color: transparent !important;
  color: red;
}

.removeCardButton:hover {
  margin-left: 0.25rem;
  border-color: red;
  border-width: 2px !important;
  background-color: red !important;
  color: white;
}

.dayName {
  display: flex;
  margin-right: 2rem;
  width: 6rem;
}

.openingTimes {
  flex: 1;
  font-size: 16px;
  font-family: "Quicksand";
}

.noteOtherInfos {
  margin-bottom: 2px;
}

.deleteButtonIcon svg {
  height: 16px;
  width: 16px;
}

.openingHoursRow {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.notesTitle {
  margin-bottom: 0px;  
}

.notesContent {
  font-family: "Quicksand";
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
}
