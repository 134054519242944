.auth-modal {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow-y: auto;
}

.auth-modal__container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.auth-modal__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;
  background-color: #718096;
  opacity: 0.75;
  min-height: 577px;
}
