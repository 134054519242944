.hidden {
  display: none;
}
.editButton {
  margin: 0.5rem 0.5rem 0.5rem 0;
}
.imageCropContainer {
  display: flex;
  height: 330px;

}
.previewCanvas {
  max-height: 300px;
}
.loadedImage {
  max-height: 330px;
}
.loadedImageMinHeight {
  min-height: 330px;
}
.buttonsDiv {
  margin-top: 20px;
  height: 32px;
}
.saveImageButton {
  margin-right: 10px;
}
.saveImageButton:hover {
  color: black !important;
}
.cancelButton {
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
}
.cancelButton:hover {
  color: black !important;
}
.imageCrop {
  display: flex;
  flex-direction: column;
  max-width: 45%;
}
.imageCropTitle {
  height: 30px;
}
.canvasContainer {
  margin-left: 20px;
}
.choseImageBtn {
  margin-top: 20px;
}
.previewCanvasListMode {
  max-width: 100%;
}
.reactCropImage {
  max-height: 300px!important;
}