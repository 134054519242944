.devToolbar {
margin-top: 5px;
margin-left: 5px;
padding: 5px 0 5px 5px;
border-radius: 5px;
background-color: rgba(0, 0, 0, 0.5);
display: inline-block;
}

.actionButton {
  padding: 4px;
  width: 32px;
  background-color: wheat;
  margin-right: 5px;
  align-items: center;
}

.devButton {
  margin-right: 5px;
}

.editButton:hover {
  background-color: rgb(0, 200, 173);
  color: white !important;
  border-color: rgb(0, 200, 173) !important;
}
