.websiteNameSettings {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font-size: 20px;
  font-weight: 700;
}

.sectionWarning {
  font-size: 13px;
  color: orange;
  margin-bottom: 15px;
}

.nameInput {
  width: 50%;
  min-width: 400px;
  margin-bottom: 10px;
}

.siteName {
  display: inline;
  color: rgb(82, 138, 82);
  font-style: italic;
  font-weight: 700;
}

.siteLink {
  display: inline;
  color: rgb(0, 198, 171);
  font-style: italic;
  font-weight: 700;
}

.selectNameButton {
  margin-top: 20px;
  width: 130px;
}

.checkIcon {
  color: green;
  font-size: 20px;
}

.xIcon {
  color: red;
  font-size: 20px;
}

.spacingDiv {
  height: 41.6px;
}

.takenText {
  color: red;
}

.deployButtonWrapper {
  margin-top: 20px;
  width: 130px;
}

.deployButton {
  background-color: rgb(227, 255, 217);
  color: green;
  border-color: green;
  margin-left: auto;
  border-width: 2px;
  font-weight: 600;
}

.deployButton:hover {
  background-color: green;
  color: white!important;
  border-color: green!important;
}

.deployButton:disabled {
  background-color: grey;
  color: white!important;
  border-color: grey!important;
}
