.listElement {
  margin-top: 20px;
  margin-left: 10px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  max-width: 60%;
  margin-bottom: 20px;
}

.titleUneditable {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 5px;
}

.valueUneditable {
  margin-bottom: 10px;
  font-size: 15px;
  font-family: "Quicksand";
}

.imageField {
    max-width: 300px;
}

.deleteElementButtonContainer {
  width: 100%;
  display: flex;
}

.deleteElementButton {
  /*background-color: rgb(255, 71, 71); */
  background-color: transparent;
  color: red;
  border-color: red;
  margin-left: auto;
}

.deleteElementButton:hover {
  /*background-color: rgb(255, 71, 71); */
  background-color: red;
  color: white!important;
  border-color: red!important;
}

.addNewElementButton {
  margin-left: 10px;
}

.addNewIcon {
  font-weight: 700;
}

.saveButtonDiv {
  margin-top: 20px;
}